<template>
  <div>
    <multiselect
      v-model="selectUser"
      ref="multiselect"
      deselect-label=""
      class="customUI col-span-4 ag-input"
      style=" max-width: 635px; overflow-x: scroll; overflow-y: hidden;"
      :multiple="true"
      @open="updateDropdownSize"
     :hide-selected="true" 
      :max-height="150"
      :taggable="true"
      placeholder=""
      open-direction="above" 
      tag-position="bottom"
      :options-limit="4"
      :options="availableUsers"
      :searchable="true"
      @select="removeTag"
      :allow-empty="true"
      @remove="removeTag"
      @tag="addTag"
    />
  </div>
</template>
<script>
import Vue from 'vue';
import Multiselect from 'vue-multiselect';
import accountModule from '@/store/modules/accountModule';
import scheduleModule from '@/store/modules/scheduleModule';

export default {
  components: {
    Multiselect,
  },

  data() {
    return {
      selectUser: [],
      wellTags: [],
      cancelBeforeStart: true,
      availableUsers: [],
      highlightAllOnFocus: true,
      value: [],
    };
  },
  async beforeMount() {
    if (this.params.value) {
      this.selectUser = this.params.value;
    
    }

      const objectID = this.params.data.Well;
     
      const wellTags = await scheduleModule.getTagsForWell({ node_id: objectID });
      this.selectUser.concat(wellTags.map((t) => JSON.parse(t)))
  },


  async created() {
     let groups = (scheduleModule.activeTableGroups)
    let payload = []
    let tags = []
    if(groups && groups.length){
      payload = groups.forEach(g => {
       if(g && g.Payload){
         g.Payload.forEach(p =>{
          //  console.log(p.Tags)
           tags = tags.concat(p.Tags)
           if(p && p.length){
             p.forEach(t => {
              //  console.log(t)
             })
           }
         })
       }
      })
      
  if(tags && tags.length){
    this.availableUsers = tags
  }
      // console.log(tags)
    }


   
    //     var div1 = document.getElementsByClassName('multiselect__content-wrapper')[0]
    //     let main = document.getElementById('ag-grid-id');
        
    // main.prepend(div1);
  },

 isPopup(){
  return true;
},

  methods: {

   activeTableGroups() {
      return scheduleModule.activeTableGroups
      
    },

  updateDropdownSize(){
	const ms = this.$refs.multiselect;
	ms.$refs.list.style.width = `${this.$el.clientWidth}px`;
	ms.$refs.list.style.position = 'fixed';
},
    async removeTag(removeT) {
      const fieldName = this.params.colDef.colId;
      const objectID = this.params.data.Well;
      const gridApi = this.params.api;
      const rowNode = gridApi.getRowNode(objectID);
      const index = this.selectUser.indexOf(removeT);
if (index > -1) {
  this.selectUser.splice(index, 1);
}
      console.log(this.selectUser)
      await this.params.context.componentParent.updateDataFromCell(rowNode.childIndex, fieldName, this.selectUser);
      await scheduleModule.addTagsToWell({ node_id: objectID, tags: this.selectUser });
    },
    async addTag(newTag) {
      console.log(newTag);
      console.log(this.params.value);
      //  this.availableUsers.push(newTag)

      const fieldName = this.params.colDef.colId;
      const objectID = this.params.data.Well;
      this.availableUsers.push(newTag);
      this.selectUser.push(newTag);
      console.log(this.params);
      this.value = this.selectUser;
      const gridApi = this.params.api;
      const rowNode = gridApi.getRowNode(objectID);
      await scheduleModule.addTagsToWell({ node_id: objectID, tags: this.selectUser });
      await this.params.context.componentParent.updateDataFromCell(rowNode.childIndex, fieldName, this.selectUser);
      this.params.api.refreshCells();

      //  var rowNode = this.gridApi.getRowNode('bb');
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css">

</style>;

<style scoped>
.customUI >>> .multiselect__tags {
    background: #283751;
    border-color: transparent;
    padding: 4px;
}

.customUI >>> .multiselect__select {
  display: none;
}

.customUI >>> .multiselect__tag {
  background: rgb(61,153,170);
}

.customUI >>> .multiselect__content-wrapper{
  position: fixed;
}

.customUI >>> .multiselect__option--highlight {
  background:rgb(61,153,170);
}

.customUI >>> .multiselect__tag-icon:after{
  background:rgb(61,153,170);
}

.customUI >>> i.multiselect__tag-icon{
  background:rgb(61,153,170);
}

.customUI >>> input {
  background: #283751;
  color: white;
}

.customUI >>> .multiselect__option--highlight:after{
  background:rgb(61,153,170);
}
.customUI >>> .multiselect__content-wrapper {
  position: fixed;

    
    position: fixed;
    z-index: 99999 !important;
    height: 150px !important;
  top: 50px;
  width: 300px;

}

.customUI >>> .multiselect--active {
  z-index: 10;
}
</style>
