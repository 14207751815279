export default {
  template: `
        <div id="foo">
        <div @click="showMenu()" class="customHeaderLabel"><span
        style="display: inline-block; vertical-align: middle; line-height: normal;"
        class="material-icons cursor-pointer"
      >
        add
      </span> Column </div>
        <div v-if="params.enableSorting" @click="onSortRequested('asc', $event)" :class="ascSort" class="customSortDownLabel">
          <i class="fa fa-long-arrow-alt-down"></i></div>
        <div v-if="params.enableSorting" @click="onSortRequested('desc', $event)" :class="descSort" class="customSortUpLabel">
          <i class="fa fa-long-arrow-alt-up"></i></div>
        <div v-if="params.enableSorting" @click="onSortRequested('', $event)" :class="noSort" class="customSortRemoveLabel">
          <i class="fa fa-times"></i>
        </div>


        <div
          v-if="showColumnMenu"
          :style="{right: menuRight, top: menuTop}"
          style="background: rgb(39 56 81); top: 315px; text-align: start;"
          class="fixed w-88 ag-theme-alpine ag-popup transform right-2 rounded-md translate-x-1
                       z-50 m-1 shadow-lgDarker py-5 px-3 pb-6"
        >
         
          <p class="text-white py-2">
            COLUMN TYPES
          </p>

    

          <div class="grid grid-cols-2 gap-2">

          <button
          @click="add('Numbers')"
          ref="buttonFilterUserMenu"
          style="text-align: start;"
          class="h-8 w-36 rounded-lg text-sm text-white  border-textgray"
        >
          <span

            style="display: inline-block; vertical-align: middle; line-height: normal;"
            class="material-icons cursor-pointer"
          >
          numbers
          </span>
          <span
            style="display: inline-block; vertical-align: middle; line-height: normal;"
            class="px-1"
          >Number</span>
        </button>


        <button
        @click="add('Text')"
        ref="buttonFilterUserMenu"
        style=""
        class="h-8 w-36 rounded-lg text-sm text-white border-textgray"
      >
        <span

          style="display: inline-block; vertical-align: middle; line-height: normal;"
          class="material-icons cursor-pointer"
        >
        text_snippet
        </span>
        <span
          style="display: inline-block; vertical-align: middle; line-height: normal;"
          class="px-1"
        >Text</span>
      </button>


      <button
      @click="add('Date')"
      ref="buttonFilterUserMenu"
      style="text-align: start;"
      class="h-8 w-36 rounded-lg text-sm text-white  border-textgray"
    >
      <span

        style="display: inline-block; vertical-align: middle; line-height: normal;"
        class="material-icons cursor-pointer"
      >
      calendar_today
      </span>
      <span
        style="display: inline-block; vertical-align: middle; line-height: normal;"
        class="px-1"
      >Date</span>
    </button>

  

          </div>
          
        </div>
        </div>
      `,
  data() {
    return {
      ascSort: null,
      descSort: null,
      noSort: null,
      columnName: '',
      menuRight: 0,
      columnType: '',
      menuTop: '315px',
      showColumnMenu: false,
    };
  },
  beforeMount() {},
  mounted() {
    this.params.column.addEventListener('sortChanged', this.onSortChanged);
    this.onSortChanged();
  },

  methods: {
    onMenuClicked() {
      this.params.showColumnMenu(this.$refs.menuButton);
    },

    showMenu() {
      let right = document.getElementById('foo').getBoundingClientRect();
      console.log(right)
      this.menuRight = `${right.right + 50  }px`;
      if(right.right > 1000){
        this.menuRight = `${0  }px`;
      }
      this.menuTop =`${right.top + 30 }px`;
      this.showColumnMenu = !this.showColumnMenu;
    },

    onSortChanged() {
      // eslint-disable-next-line no-multi-assign
      this.ascSort = this.descSort = this.noSort = 'inactive';
      if (this.params.column.isSortAscending()) {
        this.ascSort = 'active';
      } else if (this.params.column.isSortDescending()) {
        this.descSort = 'active';
      } else {
        this.noSort = 'active';
      }
    },

    add(text){
      this.params.context.componentParent.methodFromParent(text, text);
      this.columnName = ''
      this.columnType = ''

    },

    onSortRequested(order, event) {
      this.params.setSort(order, event.shiftKey);
    },
  },
};